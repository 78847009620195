<template>
  <div class="buy-crypto" :class="platform" data-t="buy-crypto">
    <ClientOnly>
      <KeepAlive :max="5">
        <FirstStep
          v-if="current === 'firstStep'"
          @go-to-next-step="goToSecondStep"
          @go-back="emit('goBack')"
        />
      </KeepAlive>
      <SecondStep
        v-if="current === 'secondStep'"
        @handle-redirect="handleRedirect"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import FirstStep from './parts/FirstStep.vue'
import SecondStep from './parts/SecondStep.vue'

const emit = defineEmits<{
  goBack: []
}>()

const { platform } = usePlatform()

const { current, goTo } = useStepper(['firstStep', 'secondStep'])

const frameUrl = ref('')
function handleRedirect() {
  if (!frameUrl.value) return

  window.open(frameUrl.value, '_blank')?.focus()
}

function goToSecondStep(data: { frameUrl: string }) {
  if (!data.frameUrl) return
  goTo('secondStep')
  frameUrl.value = data.frameUrl
}
</script>

<style scoped>
.buy-crypto {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.mobile.buy-crypto {
  margin-top: 0;
  padding-top: 0;
}
</style>
